import React from 'react';
import { Router } from "@reach/router";

/* Import the pages of our application */
import UploadHandler from "./components/UploadHandler";
import { Login, Logout } from './AuthHelpers';
import { Centre, HomeButton } from './components/UIComponents';
import Untrusted from './components/Untrusted';

/* Import the UI components that help our application */
import {
    ProtectedComponent,
    UnprotectedComponent
} from './ComponentTypes';

/* Defing 'Routes' and export */
const Routes = () => (
    <Router className="full-height">
        <ProtectedComponent path="/" as={UploadHandler} />
        <UnprotectedComponent path="/login" as={Login} />
        <UnprotectedComponent path="/logout" as={Logout} />
        <UnprotectedComponent path="/untrusted" as={Untrusted} />
        <NotFound default />
    </Router>
);

export default Routes;

const NotFound = (props) => {
    console.log(props);
    return (
        <Centre>
            <h3>404 - Not Found</h3>
            <p>Sorry, <strong>{props.uri}</strong> is not an available path.</p>
            <HomeButton />
        </Centre>
    )
}