
export default {
    s3: {
        REGION: 'eu-west-1',
        BUCKET: 'danielbrewer-data',
    },
    apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://w8vctfhqxf.execute-api.eu-west-1.amazonaws.com'
    },
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_jxXrHwiXB',
        APP_CLIENT_ID: '2smrml19s3v47ltop91r4gnkd2',
        IDENTITY_POOL_ID: 'eu-west-1:aa6d716c-1061-4e65-b22f-f951210c6451'
    }
};