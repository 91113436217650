import React, { useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from '@reach/router';
import { Loading, Centre } from './components/UIComponents';
import { FaMicrosoft, FaGoogle, FaAmazon } from "react-icons/fa";
import { Card, Button, ListGroup, ListGroupItem } from "react-bootstrap";

import UserContext from './Context';

export class Protect extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            loadingMessage: null
        };
        this.handleLogin = this.handleLogin.bind(this);
        this.timer = null;
    }

    handleLogin(user) {
        if (user) {
            this.context.setUser(user);
        } else {
            this.context.setUser(null);
        }
        this.context.setNominal();
    }

    componentDidMount() {
        this.context.setChecking();
        if (this.context.phase) {
            // Don't check for a signed in user if there
            // is something happening, like logging in.
            this.timer = setTimeout(() => {
                this.setState(
                    {
                        loadingMesssage: (<p>This is taking longer than it should...</p>)
                    }
                )
            }, 3000);
        } else {
            // Check whether the user is logged in
            checkUser().then(this.handleLogin);
        }
    }

    componentWillUnmount() {
        // If a timeout was set, cancel it
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        if (this.context.phase) {
            return (
                <Loading>
                    {this.state.loadingMesssage}
                </Loading>
            )
        } else {
            return (<Login />)
        }
    }
}

export async function checkUser() {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (e) {
        return false;
    }
}

export const Login = (props) => {
    // You could replace this with a form to log-in with
    return (
        <Centre>
            <LoginOptions />
        </Centre>
    )
}

export const Logout = (props) => {
    return (
        <Centre>
            <h1>Thanks for uploading!</h1>
            <p>You've been logged out, come again soon.</p>
        </Centre>
    );
}

const LoginOptions = (props) => {
    const userContext = useContext(UserContext);

    const federatedSignIn = _provider => {
        if (props.redirect) {
            Cache.setItem("auth_redirect", props.redirect);
        }
        userContext.setLoggingin();
        Auth.federatedSignIn({ provider: _provider });
    };

    return (
        <Card style={{ width: '18rem', backgroundColor: '#202020' }}>
            <Card.Body>
                <Card.Title>Welcome</Card.Title>
                <Card.Text>
                    Before you can send me something you need to authenticate.
                </Card.Text>
                <ListGroup>
                    <ListGroupItem as={Button} variant="google" block onClick={() => { federatedSignIn("Google"); }}>
                        <FaGoogle color="white" className="mr-3" style={{ marginBottom: ".125rem" }}>
                            Sign in using Google
                        </FaGoogle>
                        Sign in with Google
                    </ListGroupItem>
                    {false && (
                    <ListGroupItem as={Button} variant="microsoft" block onClick={() => { federatedSignIn("Microsoft"); }}>
                            <FaMicrosoft className="mr-3" style={{ marginBottom: ".125rem" }}>
                                Sign in using Microsoft
                        </FaMicrosoft>
                        Sign in with Microsoft
                    </ListGroupItem>
                    )}
                        <ListGroupItem as={Button} variant="amazon" block onClick={() => { federatedSignIn("LoginWithAmazon"); }}>
                            <FaAmazon className="mr-3" style={{ marginBottom: ".125rem" }}>
                                Sign in using Amazon
                        </FaAmazon>
                        Sign in with Amazon
                    </ListGroupItem>
                </ListGroup>
            </Card.Body>
            <hr style={{ margin: "0", padding: "0" }}/>
            <Card.Body>
                <Card.Text>
                    If you would rather not authenticate directly using this device, but still upload from it, use the Remote Authentication option.
                </Card.Text>
                <ListGroup>
                    <ListGroupItem as={Link} variant="untrusted" to="/untrusted">
                        Remote Authenticate
                    </ListGroupItem>
                </ListGroup>
            </Card.Body>
        </Card>
    );
};