import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from "aws-amplify";
import './index.css';

import config from './config';
import App from './App';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCheckSquare, faFileUpload, faClock,
    faTachometerAlt, faDownload, faSignOutAlt,
    faInfoCircle
 } from '@fortawesome/free-solid-svg-icons';

import * as serviceWorker from './serviceWorker';

library.add(
    faCheckSquare, faFileUpload, faClock,
    faTachometerAlt, faDownload, faSignOutAlt,
    faInfoCircle
);

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,

        oauth: {
            domain: 'auth.danielbrewer.org',
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://ingest.danielbrewer.org/',
            redirectSignOut: 'https://ingest.danielbrewer.org/logout/',
            //redirectSignIn: 'http://localhost:3000/',
            //redirectSignOut: 'http://localhost:3000/logout/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            {
                name: "untrustedAuth",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            }
        ]
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
