import React, { useContext, useState } from 'react';
import { Link } from '@reach/router';
import {
    Container, Row, Col, Spinner, Navbar,
    Button, ButtonGroup, Modal, Dropdown
} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import UserContext from '../Context';
import NotificationCentre from './NotificationCentre';
import { Logo } from './Branding';

export const Navigation = (props) => {
    const userContext = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const login = () => {
        console.log('login');
        userContext.setLoggingin();
        Auth.federatedSignIn({provider: "Google"});
    }

    const logout = (_global) => {
        closeModal();
        console.log('logout');
        userContext.setLoggingout();
        Auth.signOut({ global: _global })
            .then(() => {
                userContext.setUser(null);
            })
            .catch((err) => {
                console.log('Couldn\'t sign out');
                console.log(err);
                userContext.setUser(null);
            })
    }

    return (
        <>
            <Navbar variant="db" bg="db" fixed="top" >
                <Navbar.Brand href="/"><Logo variant="dark-green" /></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text className="p-0">
                        <ButtonGroup aria-label="Basic example">
                            {userContext.user ? (
                                <>
                                    <NotificationCentre />
                                    <Button size="sm" variant="dark-grey" onClick={openModal}>Logout</Button>
                                </>
                            ) : 
                                <Dropdown as={ButtonGroup} alignRight>
                                    <Button size="sm" variant="dark-grey" onClick={login}>Login</Button>

                                    <Dropdown.Toggle split variant="dark-grey" id="dropdown-split-basic" />

                                    <Dropdown.Menu className="dark-grey">
                                        <Dropdown.Item className="dark-grey-dropdown" size="sm" as={Link} to="/untrusted">Remote Grant</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </ButtonGroup>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
            <LogoutModal
                show={showModal}
                onHide={closeModal}
                onLogout={logout}
                onFullLogout={() => { logout(true) }} />
        </>
    )
}

export const Loading = (props) => {
    return (
        <Container className="middle-align">
            <Row>
                <Col sm style={{ textAlign: 'center' }}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    {props.children}
                </Col>
            </Row>
        </Container>
    )
}

function LogoutModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you leaving?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
                <Dropdown as={ButtonGroup}>
                    <Button onClick={props.onLogout} variant="dark-green">Logout</Button>

                    <Dropdown.Toggle split variant="dark-green" id="dropdown-split-basic" />

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={props.onLogout}>Logout of this device</Dropdown.Item>
                        <Dropdown.Item onClick={props.onFullLogout}>Logout of all devices</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Modal.Body>
        </Modal>
    );
}

export const HomeButton = (props) => {
    return <Button as={Link} to="/" size="sm" variant="dark-green">Home</Button>
}

export const Centre = (props) => {
    return (
        <Container className="middle-align">
            <Row>
                <Col sm style={{ textAlign: 'center' }}>
                    {props.children}
                </Col>
            </Row>
        </Container>
    )
}





/*
export class Login extends React.Component {

    static contextType = UserContext;

    render() {
        return (<p>Please sign in</p>)
    }
}

export async function checkUser() {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (e) {
        return false;
    }
}

export const Logout = (props) => {
    return (
        <Container className="middle-align">
            <Row>
                <Col sm style={{ textAlign: 'center' }}>
                    <h1>Thanks for uploading!</h1>
                    <p>You've been logged out, come again soon.</p>
                </Col>
            </Row>
        </Container>
    );
}


export const UnprotectedComponent = (props) => {
    let { as: Comp, ...other_props } = props;
    return (
        <Container fluid className="main">
            <Comp {...other_props} />
        </Container>
    )
}

export class ProtectedComponent extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };

        this.handleResult = this.handleResult.bind(this);
    }

    handleResult(data) {
        if (data) {
            this.context.setUser(data);
        } else {
            this.context.setUser(null);
        }
        this.setState({ loading: false });
    }

    componentDidMount() {
        this.setState({ loading: true });
        checkUser().then(this.handleResult);
    }

    render() {
        var content = <Login />
        if (this.state.loading) {
            content = <Loading />

        } else {
            if (this.context.user) {
                let { as: Comp, ...other_props } = this.props;
                content = <Comp {...other_props} />
            } else {
                content = <Login />
            }
        }

        return (
            <Container fluid className="main">
                {content}
            </Container>
        );
    }
}


/*
export const ProtectedComponent = (props) => {

    const userContext = useContext(UserContext);

    const [loading, setLoading] = useState(true);

    var content = <Login />

    const handleResult = (user) => {
        if (user) {
            userContext.setUser(user);
        } else {
            userContext.setUser(null);
        }
        setLoading(false);
    }


    let { as: Comp, ...other_props } = props;
    if (userContext.user) {
        //setLoading(false);
        content = <Comp {...other_props} />
    } else {
        checkUser().then(handleResult);
    }

    if (loading) {
        content = <Loading />
    }



    return (
        <Container fluid className="main">
            {content}
        </Container>
    );
}



*/