import React, { Component } from "react";
import { Centre } from './UIComponents';
import {
  OverlayTrigger, Popover, Button
} from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Upload, { UploadComponent } from './Upload';
import FileInput from './FileInput';
import uuidv4 from 'uuid/v4';
import { Circle } from 'rc-progress';

class UploadHandler extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uploads: {}
    };

    this.interval = null;

    this.addToQueue = this.addToQueue.bind(this);
    this.update = this.update.bind(this);
  }

  update() {
    const _aggregatePercentage = this.aggregatePercentage(this.state.uploads);
    this.setState({
      aggregatePercentage: _aggregatePercentage
    });
    if (_aggregatePercentage === 100 && this.interval) {
      clearInterval(this.interval);
    }
  }

  addToQueue(files) {
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      const uuid = uuidv4();

      const upload = new Upload(file);
      upload.start();

      // eslint-disable-next-line no-loop-func
      this.setState((prevState) => {
        prevState.uploads[uuid] = upload;
        return { uploads: prevState.uploads };
      });
    }

    this.interval = setInterval(this.update, 200);

    console.log(this.state.uploads);
  }

  aggregatePercentage(uploads) {
    var total = 0;
    var done = 0;
    for( var id in uploads ) {
      if( uploads.hasOwnProperty( id ) ) {
        total += parseFloat( uploads[id].info.mbTotal );
        done += parseFloat( uploads[id].info.mbDone );
      }
    }

    return ((done/total) * 100);
  }

  render() {
    const items = Object.entries(this.state.uploads).map(([uuid, upload]) => (
      <ListGroup.Item key={uuid}>
        <UploadComponent key={uuid} upload={upload} />
      </ListGroup.Item>
    ));
    return (
      <>
        <Centre>
          <FileInput onFilesAdded={this.addToQueue} />
        </Centre>
        {items.length > 0 &&
          <OverlayTrigger
            trigger="click"
            placement="left"
            rootClose
            overlay={
              <Popover style={{ width: '25em' }} >
                <Popover.Content>
                  <ListGroup variant="flush" >
                    {items}
                  </ListGroup>
                </Popover.Content>
              </Popover>
            }
          >
            <Button style={{
              position: 'absolute',
              right: '1rem',
              bottom: '1rem',
              background: 'none',
              border: 'none',
              width: '4rem',
              height: '4rem',
              padding: '0',
              borderRadius: '.5rem',
              backgroundColor: '#202020'
            }}
              variant="secondary"
            >
              {this.state.aggregatePercentage === 100 ? <FontAwesomeIcon icon="check-square" /> : <Circle
          style={{ width: '2rem' }}
          percent={this.state.aggregatePercentage}
          strokeWidth="10"
          strokeLinecap="round"
          strokeColor={{
            '100%': '#00dbde',
            '0%': '#fc00ff',
          }}>
            </Circle> }</Button>
          </OverlayTrigger>
        }
      </>
    )
  }
}

export default UploadHandler;
