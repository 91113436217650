import React from 'react';
import { Hub } from 'aws-amplify';
import Routes from "./Routing";
import { Navigation } from './components/UIComponents';
import { notify } from './components/NotificationCentre';
import { UserProvider } from './Context';
import { checkUser } from './AuthHelpers';
import "./App.css";

export const PhaseTypes = {
    LOGIN: 'logging_in',
	LOGOUT: 'logging_out',
	CHECKING: 'checking',
    NOMINAL: null
}

class App extends React.Component {

	constructor(props) {
		super(props);

		const urlParams = new URLSearchParams(window.location.search);

		const code = urlParams.get('code');
		const state = urlParams.get('state');

		var _phase = PhaseTypes.NOMINAL;
		if (code && state) {
			_phase = PhaseTypes.LOGIN;
		}

		this.state = {
			user: null,
			setUser: user => {
				console.log('setUser');
				if (user) {
					console.log(user);
					console.log(user.signInUserSession.accessToken.jwtToken)
					if (this.state.user && user.hasOwnProperty('attributes')) {
						if (!this.state.user.hasOwnProperty('attributes')) {
							notify('Welcome!', 'You\'ve signed in as ' + user.attributes.name);
						} else if (this.state.user.hasOwnProperty('attributes')) {
							if (this.state.user.attributes !== user.attributes) {
								try {
									notify('Hello again!', 'You\'re still signed in as ' + user.attributes.name);
								} catch(err) {
									console.log(err);
								}
							}
						}
					}
					this.setState({ user });
					this.setState({ phase: null });
				}
			},
			phase: _phase,
			setLoggingin: () => {
				console.log('setLoggingin');
				this.setState({ phase: PhaseTypes.LOGIN });
			},
			setLoggingout: () => {
				console.log('setLoggingout');
				this.setState({ phase: PhaseTypes.LOGOUT });
			},
			setNominal: () => {
				console.log('setNominal');
				this.setState({ phase: PhaseTypes.NOMINAL });
			},
			setChecking: () => {
				console.log('setChecking');
				this.setState({ phase: PhaseTypes.CHECKING });
			}
		};
	}

	componentDidMount() {

		checkUser().then(this.state.setUser);

		Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
					console.log('Hub - signIn');
					checkUser().then(this.state.setUser);
                    break;
                case "signOut":
					console.log('Hub - signOut');
                    this.state.setUser(null);
                    notify('Signed out!', 'Goodbye!');
                    break;
                case "oAuthSignOut":
					console.log('Hub - oAuthSignOut');
                    this.state.setUser(null);
					break;
				case "cognitoHostedUI":
					console.log('Hub - cognitoHostedUI');
					this.state.setUser(data);
					break;
				case "signIn_failure":
					console.log('Hub - signIn_failure');
					this.state.setUser(data);
					this.state.setRetrieving(false);
					break;
                default:
                    console.log('Unknown event');
                    console.log(event);
                    console.log(data);
            }
        });
	}	

	render() {
		return (
			<UserProvider value={this.state}>
				<Navigation />
				<Routes />
			</UserProvider>
		)
	}
}

export default App;