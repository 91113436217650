import React from 'react';

export const Logo = (props) => {
    return (
        <svg
            version="1.1"
            style={
                {
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                    imageRendering: "optimizeQuality",
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    height: '40px',
                    verticalAlign: 'middle',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    padding: '.1rem 0',
                    maxWidth: '40vw'
                }
            }
            viewBox="0 0 600 75">
            <defs>
                <linearGradient id="LinearGradient" x1="0" y1="0" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00dbde" offset="0%" />
                    <stop stopColor="#fc00ff" offset="100%" />
                </linearGradient>
            </defs>
            <g id="logo-group">
                <path d="M-123.462+55.8289M61.9983+27.4211C60.2831+24.5804+57.9247+22.3828+54.9231+20.7212C51.9216+19.1132+48.4376+18.3092+44.5784+18.3092L29.7314+18.3092L29.7314+20.7748L44.364+20.7748C47.7944+20.7748+50.8496+21.4716+53.5295+22.8652C56.1559+24.2588+58.1927+26.1884+59.6399+28.6003C61.0335+31.0659+61.7839+33.9067+61.7839+37.0691C61.7839+40.285+61.0335+43.0722+59.6399+45.5378C58.1927+48.0034+56.1559+49.933+53.5295+51.3265C50.8496+52.7201+47.7944+53.3633+44.364+53.3633L32.4649+53.3633L32.4649+37.0691L29.7314+37.0691L29.7314+55.8289L44.5784+55.8289C48.4376+55.8289+51.9216+55.0249+54.9231+53.4169C57.9247+51.8089+60.2831+49.6114+61.9983+46.7706C63.6599+43.9298+64.5175+40.7138+64.5175+37.0691C64.5175+33.4779+63.6599+30.2619+61.9983+27.4211Z" fill="url(#LinearGradient)" />
                <path d="M-104.851+55.8289M105.823+18.3092L103.089+18.3092L85.8301+55.8289L88.7781+55.8289L104.429+21.3108L120.134+55.8289L123.082+55.8289L105.823+18.3092Z" fill="url(#LinearGradient)" />
                <path d="M-86.2402+55.8289M179.056+18.3092L176.322+18.3092L176.322+50.8977L150.809+18.3092L148.504+18.3092L148.504+55.8289L151.238+55.8289L151.238+23.2404L176.805+55.8289L179.056+55.8289L179.056+18.3092Z" fill="url(#LinearGradient)" />
                <path d="M-133.629+55.8289M270.117+39.6418C268.723+38.0338+266.686+37.0155+264.007+36.4795C265.882+35.8899+267.437+34.8179+268.562+33.3171C269.688+31.8699+270.278+30.1011+270.278+27.9035C270.278+24.8484+269.098+22.49+266.847+20.8284C264.596+19.1668+261.434+18.3092+257.36+18.3092L241.709+18.3092L241.709+20.6676L257.253+20.6676C260.523+20.6676+263.042+21.3108+264.811+22.5972C266.579+23.8836+267.49+25.706+267.49+28.118C267.49+30.5835+266.579+32.4595+264.811+33.7459C263.042+35.0323+260.523+35.6219+257.253+35.6219L241.709+35.6219L241.709+37.9803L258.432+37.9803C262.023+37.9803+264.811+38.6234+266.686+39.8562C268.562+41.1426+269.527+43.0722+269.527+45.6986C269.527+48.3786+268.562+50.3081+266.686+51.5945C264.811+52.8809+262.077+53.4705+258.432+53.4705L241.709+53.4705L241.709+55.8289L258.432+55.8289C262.988+55.8289+266.418+54.9713+268.777+53.2561C271.082+51.5409+272.261+49.129+272.261+45.913C272.261+43.3938+271.51+41.3034+270.117+39.6418Z" fill="url(#LinearGradient)" />
                <path d="M-115.018+55.8289M320.403+42.697C323.19+41.893+325.387+40.4994+326.888+38.4626C328.389+36.4259+329.193+33.9603+329.193+30.9587C329.193+27.046+327.853+23.9372+325.173+21.686C322.493+19.4348+318.795+18.3092+314.078+18.3092L300.624+18.3092L300.624+20.7748L314.078+20.7748C318.044+20.7748+321.099+21.686+323.243+23.4548C325.334+25.2236+326.406+27.7427+326.406+30.9587C326.406+34.2283+325.334+36.7475+323.243+38.5162C321.099+40.285+318.044+41.1426+314.078+41.1426L300.624+41.1426L300.624+55.8289L303.358+55.8289L303.358+43.5546L314.078+43.5546C315.15+43.5546+316.383+43.501+317.723+43.2866L326.674+55.8289L329.783+55.8289L320.403+42.697Z" fill="url(#LinearGradient)" />
                <path d="M-96.4073+55.8289M382.145+35.5683L357.865+35.5683L357.865+37.9803L382.145+37.9803L382.145+35.5683ZM382.145+18.3092L357.865+18.3092L357.865+20.7748L382.145+20.7748L382.145+18.3092ZM357.865+53.3633L357.865+55.8289L382.145+55.8289L382.145+53.3633L357.865+53.3633Z" fill="url(#LinearGradient)" />
                <path d="M-77.7964+55.8289M462.029+18.3092L459.349+18.3092L447.825+52.1305L436.14+18.3092L433.514+18.3092L421.776+52.0769L410.359+18.3092L407.465+18.3092L420.221+55.8289L423.116+55.8289L434.8+22.1684L446.431+55.8289L449.326+55.8289L462.029+18.3092Z" fill="url(#LinearGradient)" />
                <path d="M-59.1854+55.8289M512.748+35.5683L488.467+35.5683L488.467+37.9803L512.748+37.9803L512.748+35.5683ZM512.748+18.3092L488.467+18.3092L488.467+20.7748L512.748+20.7748L512.748+18.3092ZM488.467+53.3633L488.467+55.8289L512.748+55.8289L512.748+53.3633L488.467+53.3633Z" fill="url(#LinearGradient)" />
                <path d="M-40.5745+55.8289M562.764+42.697C565.551+41.893+567.748+40.4994+569.249+38.4626C570.75+36.4259+571.554+33.9603+571.554+30.9587C571.554+27.046+570.214+23.9372+567.534+21.686C564.854+19.4348+561.156+18.3092+556.439+18.3092L542.985+18.3092L542.985+20.7748L556.439+20.7748C560.405+20.7748+563.461+21.686+565.604+23.4548C567.695+25.2236+568.767+27.7427+568.767+30.9587C568.767+34.2283+567.695+36.7475+565.604+38.5162C563.461+40.285+560.405+41.1426+556.439+41.1426L542.985+41.1426L542.985+55.8289L545.719+55.8289L545.719+43.5546L556.439+43.5546C557.511+43.5546+558.744+43.501+560.084+43.2866L569.035+55.8289L572.144+55.8289L562.764+42.697Z" fill="url(#LinearGradient)" />
            </g>
        </svg>
    )
}

export const Background = (props) => {
    var color = "#000000"

    switch (props.variant) {
        case "light-green":
            color = "#ABC8C7";
            break;
        case "dark-green":
            color = "#0D5257";
            break;
        case "black":
            color = "#000000";
            break;
        case "white":
            color = "#ffffff";
            break;
        default:
            color = "#ffffff";
    }

    return (
        <svg
            width="5rem"
            version="1.1"
            style={
                {
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                    imageRendering: "optimizeQuality",
                    fillRule: "evenodd",
                    clipRule: "evenodd"
                }
            }
            viewBox="0 0 11250 7042">
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="842,12 209,203 58,846 539,1299 1172,1109 1323,465 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="2166,12 1534,203 1382,846 1863,1299 2496,1109 2648,465 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="3491,12 2858,203 2706,846 3188,1299 3821,1109 3972,465 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="4815,12 4182,203 4031,846 4512,1299 5145,1109 5296,465 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="6139,12 5506,203 5355,846 5836,1299 6469,1109 6621,465 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="7463,12 6831,203 6679,846 7160,1299 7793,1109 7945,465 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="8788,12 8155,203 8003,846 8485,1299 9118,1109 9269,465 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="10112,12 9479,203 9328,846 9809,1299 10442,1109 10593,465 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="1489,1152 856,1342 705,1986 1186,2438 1819,2248 1970,1605 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="2813,1152 2180,1342 2029,1986 2510,2438 3143,2248 3294,1605 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="4137,1152 3505,1342 3353,1986 3834,2438 4467,2248 4619,1605 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="5462,1152 4829,1342 4677,1986 5159,2438 5792,2248 5943,1605 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="6786,1152 6153,1342 6002,1986 6483,2438 7116,2248 7267,1605 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="8110,1152 7477,1342 7326,1986 7807,2438 8440,2248 8591,1605 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="9434,1152 8802,1342 8650,1986 9131,2438 9764,2248 9916,1605 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="10759,1152 10126,1342 9974,1986 10456,2438 11089,2248 11240,1605 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="827,2305 194,2495 42,3138 524,3591 1157,3401 1308,2757 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="2151,2305 1518,2495 1367,3138 1848,3591 2481,3401 2632,2757 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="3475,2305 2842,2495 2691,3138 3172,3591 3805,3401 3957,2757 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="4799,2305 4167,2495 4015,3138 4497,3591 5129,3401 5281,2757 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="6124,2305 5491,2495 5339,3138 5821,3591 6454,3401 6605,2757 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="7448,2305 6815,2495 6664,3138 7145,3591 7778,3401 7929,2757 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="8772,2305 8139,2495 7988,3138 8469,3591 9102,3401 9254,2757 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="10097,2305 9464,2495 9312,3138 9794,3591 10426,3401 10578,2757 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="1473,3444 841,3635 689,4278 1171,4731 1803,4540 1955,3897 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="2798,3444 2165,3635 2013,4278 2495,4731 3128,4540 3279,3897 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="4122,3444 3489,3635 3338,4278 3819,4731 4452,4540 4603,3897 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="5446,3444 4813,3635 4662,4278 5143,4731 5776,4540 5928,3897 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="6770,3444 6138,3635 5986,4278 6468,4731 7100,4540 7252,3897 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="8095,3444 7462,3635 7310,4278 7792,4731 8425,4540 8576,3897 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="9419,3444 8786,3635 8635,4278 9116,4731 9749,4540 9900,3897 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="10743,3444 10110,3635 9959,4278 10440,4731 11073,4540 11225,3897 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="800,4600 167,4790 15,5434 497,5887 1130,5696 1281,5053 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="2124,4600 1491,4790 1340,5434 1821,5887 2454,5696 2605,5053 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="3448,4600 2815,4790 2664,5434 3145,5887 3778,5696 3930,5053 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="4772,4600 4140,4790 3988,5434 4469,5887 5102,5696 5254,5053 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="6097,4600 5464,4790 5312,5434 5794,5887 6427,5696 6578,5053 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="7421,4600 6788,4790 6637,5434 7118,5887 7751,5696 7902,5053 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="8745,4600 8112,4790 7961,5434 8442,5887 9075,5696 9227,5053 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="10069,4600 9437,4790 9285,5434 9766,5887 10399,5696 10551,5053 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="1446,5739 814,5930 662,6573 1143,7026 1776,6836 1928,6192 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="2771,5739 2138,5930 1986,6573 2468,7026 3101,6836 3252,6192 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="4095,5739 3462,5930 3311,6573 3792,7026 4425,6836 4576,6192 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="5419,5739 4786,5930 4635,6573 5116,7026 5749,6836 5901,6192 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="6743,5739 6111,5930 5959,6573 6440,7026 7073,6836 7225,6192 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="8068,5739 7435,5930 7283,6573 7765,7026 8398,6836 8549,6192 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="9392,5739 8759,5930 8608,6573 9089,7026 9722,6836 9873,6192 " />
            <polygon stroke={color} stroke-width="27.7795" fill="none" fillRule="nonzero" points="10716,5739 10083,5930 9932,6573 10413,7026 11046,6836 11198,6192 " />
        </svg>
    )
}