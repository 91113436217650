import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import UserContext from './Context';
import { Protect } from './AuthHelpers';

export const UnprotectedComponent = (props) => {
    let { as: Comp, ...other_props } = props;
    return (
        <Container fluid className="main">
            <Comp {...other_props} />
        </Container>
    )
}

export const ProtectedComponent = (props) => {
    const userContext = useContext(UserContext);
    let { as: Comp, ...other_props } = props;
    var content = <Protect redirect={props.path} protected={true} />
    if (userContext.user) {
        content = <Comp {...other_props} />
    }

    return (
        <Container fluid className="main">
            {content}
        </Container>
    );
}
