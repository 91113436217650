import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Storage } from 'aws-amplify';
//import SpeedChart from './SpeedChart';
//import notify from './Notifications';

function pad(n, width = 2) {
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(0) + n;
}

function timeConverter(UNIX_timestamp) {
	var a = new Date(UNIX_timestamp);
	var year = a.getFullYear();
	var month = a.getMonth();
	var date = a.getDate();
	var hour = a.getHours();
	var min = a.getMinutes();
	var time = pad(hour) + ':' + pad(min) + ' ' + pad(date) + '/' + pad(month) + '/' + year;
	return time;
}

function timePrettifier(microseconds) {
	const total_seconds = Math.floor(microseconds / 1000);
	const minutes = Math.floor(total_seconds / 60);
	const seconds = total_seconds % 60;
	return `${pad(minutes)}:${pad(seconds)}`;
}

class Upload {
	constructor(file) {
		this.file = file;

		this.progressCallback = (progress) => { console.log(progress); }
		this.infoCallback = (info) => { console.log(info); }
		this.onFinishCallback = (success) => { console.log(success); }

		this.progressProxy = this.progressProxy.bind(this);
		this.infoCallback = this.infoCallback.bind(this);
		this.onFinishCallback = this.onFinishCallback.bind(this);

		this.history = {
			0: 0
		}

		this.info = {
			percentage: 0,
			label: 'Starting...',
			startTime: Date.now(),
			speed: 0,
			finished: false,
			status: 'uploading',
			mbDone: 0,
			mbTotal: (this.file.size / 1000000).toFixed(2),
			history: {
				x: 'x',
				columns: [
					["MB/s"],
					["x"]
				]
			}
		};
	}

	async start() {
		console.log('start');
		Storage.put(this.file.name, this.file, {
			contentType: this.file.type,
			level: 'private',
			progressCallback: this.progressProxy
		})
			.then(result => {
				this.onFinish(true);
			})
			.catch(err => {
				this.onFinish(false, err);
			});

		this.info.startTime = Date.now();
	}

	updateInfo(progress) {
		if (progress.loaded && progress.total) {
			// Only update anything if the measures exist
			this.info.percentage = (Math.round((progress.loaded / progress.total) * 10000) / 100);
			this.info.label = `${this.info.percentage}%`;
			this.info.mbDone = (progress.loaded / 1000000).toFixed(2);
			this.info.mbTotal = (progress.total / 1000000).toFixed(2);
			this.info.elapsedTime = (Date.now() - this.info.startTime);
			this.info.speed = parseFloat(((progress.loaded / 1000) / this.info.elapsedTime).toFixed(2));

			this.history[this.info.percentage] = this.info.speed;

			this.info.history = {
				x: 'x',
				columns: [
					(["x"]).concat(Object.keys(this.history).map(Number)),
					(["MB/s"]).concat(Object.values(this.history))
				]
			}
		}
	}

	progressProxy(progress) {
		console.log('progressProxy');
		this.updateInfo(progress);
	}

	setProgressCallback(callback) {
		console.log('setProgressCallback');
		this.progressCallback = callback;
	}

	onFinish(success, err = null) {
		console.log('onFinish');
		this.info.finished = true;
		if (success) {
			this.info.percentage = 100;
			this.info.speed = 0;
			this.info.label = 'Complete';
			this.info.status = 'success';
			console.log('File uploaded!', `${this.file.name} has been uploaded.`);
		} else {
			this.info.percentage = 100;
			this.info.speed = 0;
			this.info.label = 'Error';
			this.info.status = 'error';
			this.info.activity = (
				<Row>
					<Col sm><small>{err.message}</small></Col>
				</Row>
			);
			console.log('Upload failed!', `${this.file.name} failed to be uploaded.`);
		}
		this.onFinishCallback(success);
	}

	setOnFinishCallback(callback) {
		console.log('setOnFinishCallback');
		this.onFinishCallback = callback;
	}

}























































































export class UploadComponent extends Component {
	constructor(props) {
		super(props);
		if (props.upload) {
			this.upload = props.upload;
		} else {
			console.error('No upload presented');
		}

		this.state = this.upload.info;

		this.interval = null;

		this.update = this.update.bind(this);
		this.uploadFinished = this.uploadFinished.bind(this);

		this.plot = React.createRef();

	}

	update() {
		this.setState(() => {
			return this.upload.info;
		})
	}

	componentDidMount() {
		this.upload.setOnFinishCallback(this.uploadFinished);
		if (!this.upload.info.finished) {
			this.interval = setInterval(this.update, 50);
		}
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	uploadFinished(success) {
		if (this.interval) {
			clearInterval(this.interval);
		}
		this.update();
	}

	render() {
		return (
			<Container className="p-0" >
				<OverlayTrigger
					key="left"
					placement="left"
					rootClose
					overlay={
						<Tooltip>
							<div className="p-2">
								<small><FontAwesomeIcon icon="download" className="mr-1" /><strong>{this.state.mbDone}MB/{this.upload.info.mbTotal}MB</strong></small>
								<br />
								<small><FontAwesomeIcon icon="tachometer-alt" className="mr-1" /><strong>{this.state.speed}MB/s</strong></small>
								<br />
								<small><FontAwesomeIcon icon="clock" className="mr-1" /><strong>{timePrettifier(this.state.elapsedTime)}</strong></small>
							</div>
						</Tooltip>
					}
				>
					<>
						<Row>
							<Col sm={12}>
								<p className="mb-1" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>File: <strong>{this.upload.file.name}</strong></p>
							</Col>
							<Col sm={12} style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
								<small>Modified: <strong>{timeConverter(this.upload.file.lastModified)}</strong></small>
							</Col>
						</Row>
						<Row>
							<Col sm={12} className="m-1">
								<ProgressBar now={this.state.percentage} label={this.state.label} variant={this.state.status} />
							</Col>
						</Row>
					</>
				</OverlayTrigger>
			</Container >
		);
	}
}

export default Upload;
