import React, { Component } from "react";
import { Centre } from './UIComponents';
import {
  OverlayTrigger, Popover//, Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import ListGroup from 'react-bootstrap/ListGroup';
//import Upload, { UploadComponent } from './Upload';
//import FileInput from './FileInput';
//import uuidv4 from 'uuid/v4';
//import { Circle } from 'rc-progress';

const popover = (
    <Popover id="popover-basic" className="dark-grey">
        <Popover.Title as="h3" className="dark-grey">What is this?</Popover.Title>
        <Popover.Content style={{ color: "white", padding: '1rem' }}>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
        </Popover.Content>
    </Popover>
    );

const Info = () => (
<OverlayTrigger rootClose trigger="click" placement="top" overlay={popover}>
    <FontAwesomeIcon icon="info-circle" style={{
        margin: '1rem',
        color: '#ffffff',
        position: "relative",
        top: '100%',
        float: 'left'
    }} />
</OverlayTrigger>
);

class Untrusted extends Component {

    render() {
        return (
            <>
                <Info />
                <Centre>
                    <h3>Untrusted</h3>
                    <p>This has yet to be implemented, hopefully it will be soon.</p>
                </Centre>
            </>
        )
    }
}

export default Untrusted;